
<template>
    <div class="row">
        <div class="col-md-3">
            <div v-if="!editMode">
                <div class="box box-widget widget-user-2">
                    <div class="widget-user-header bg-default">
                        <h3 style="margin-left: 0px;" class="widget-user-username">
                            <span>{{currentOperation.description}}</span> 
                        </h3>
                    </div>
                </div>
                <div class="box box-widget">
                    <ul class="nav nav-stacked info-rows"> 
                        <li>
                            <div class="row">
                                <div class="col-xs-4"></div>
                                <div class="col-xs-8">
                                    <button @click="enableEdit()" type="button" class="btn btn-default pull-right"><span><i class="fa fa-edit"></i> Upravit</span></button>    
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="row">
                                <div class="col-xs-4">
                                    <span v-if="currentOperation.type == 0">Popis práce</span>
                                    <span v-else>Popis materiálu</span>
                                </div>
                                <div class="col-xs-8">
                                    <div class="pull-right">
                                        <span class="ml-auto">{{currentOperation.description}}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="row">
                                <div class="col-xs-4">
                                    <span>Typ</span>
                                </div>
                                <div class="col-xs-8">
                                    <div class="pull-right">
                                        <span class="ml-auto">{{currentOperation.type == 0 ? "Práce" : "Materiál"}}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="row">
                                <div class="col-xs-4">
                                    <span v-if="currentOperation.type == 0" style="white-space: nowrap">Fakturovaná cena za 1 hod bez DPH</span>
                                    <span v-else style="white-space: nowrap">Prodejní cena za jednotku bez DPH</span>
                                </div>
                                <div class="col-xs-8">
                                    <div class="pull-right">
                                        <span class="ml-auto">{{currentOperation.invoice_price}}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="row">
                                <div class="col-xs-4">
                                    <span style="white-space: nowrap">DPH</span>
                                </div>
                                <div class="col-xs-8">
                                    <div class="pull-right">
                                        <span class="ml-auto">{{currentOperation.dph}}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="row">
                                <div class="col-xs-4">
                                    <span style="white-space: nowrap">{{currentOperation.type == 1 ? "Množství" : "Doba trvání operace v hod"}}</span>
                                </div>
                                <div class="col-xs-8">
                                    <div class="pull-right">
                                        <span class="ml-auto">{{currentOperation.amount}}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="formResponses.type == 'Materiál' || currentOperation.type == 0">
                            <div class="row">
                                <div class="col-xs-4">
                                    <span style="white-space: nowrap">Nákupní cena</span>
                                </div>
                                <div class="col-xs-8">
                                    <div class="pull-right">
                                        <span class="ml-auto">{{currentOperation.price}}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-else>
                <div class="box box-widget widget-user-2">
                    <div class="widget-user-header form-group">
                        <div :class="{'has-error': $v.formResponses.description.$error}">
                            <input class="form-control" v-model="$v.formResponses.description.$model" type="text">
                            <span v-if="!$v.formResponses.description.required" class="help-block">Povinný údaj</span>
                            <span v-if="!$v.formResponses.description.maxLength" class="help-block">Může mít maximálně {{$v.formResponses.description.$params.maxLength.max}} znaků!</span>
                        </div>
                    </div>
                </div>
                <div class="box box-widget">
                    <ul class="nav nav-stacked info-rows"> 
                        <li>
                            <div class="row">
                                <div class="col-xs-4">
                                    <span>Typ</span>
                                </div>
                                <div class="col-xs-8">
                                    <Select2 v-model="formResponses.type" :options="['Práce', 'Materiál']"/>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="row">
                                <div class="col-xs-4">
                                    <span v-if="formResponses.type == 'Práce'">Fakturovaná cena za 1 hod bez DPH</span>
                                    <span v-else>Prodejní cena za jednotku bez DPH</span>
                                </div>
                                <div class="col-xs-8">
                                    <input class="form-control" v-model="$v.formResponses.invoice_price.$model" type="number">
                                    <span v-if="!$v.formResponses.invoice_price.required" class="help-block">Povinný údaj</span>
                                    <span v-if="!$v.formResponses.invoice_price.decimal" class="help-block">Musí být číslo!</span>
                                    <span v-if="!$v.formResponses.invoice_price.minValue" class="help-block">Číslo musí být kladné!</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="row">
                                <div class="col-xs-4">
                                    <span>DPH</span>
                                </div>
                                <div class="col-xs-8">
                                    <Select2 v-model="formResponses.dph" :options="['21%', '12%', '0%']"/>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="row">
                                <div class="col-xs-4">
                                    <span>{{formResponses.type == "Práce" ? "Doba trvání operace v hod" : "Množství"}}</span>
                                </div>
                                <div class="col-xs-8">
                                    <div :class="{'has-error': $v.formResponses.amount.$error}">
                                        <input class="form-control" v-model="$v.formResponses.amount.$model" type="number">
                                        <span v-if="!$v.formResponses.amount.required" class="help-block">Povinný údaj</span>
                                        <span v-if="!$v.formResponses.amount.decimal" class="help-block">Musí být číslo!</span>
                                        <span v-if="!$v.formResponses.amount.minValue" class="help-block">Číslo musí být kladné!</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="formResponses.type == 'Materiál'">
                            <div class="row">
                                <div class="col-xs-4">
                                    <span>Nákupní cena za jednotku bez DPH</span>
                                </div>
                                <div class="col-xs-8">
                                    <div :class="{'has-error': $v.formResponses.price.$error}">
                                        <input type="text" class="form-control" id="inputEmail" v-model="$v.formResponses.price.$model">
                                        <span v-if="!$v.formResponses.price.required" class="help-block">Povinný údaj</span>
                                        <span v-if="!$v.formResponses.price.decimal" class="help-block">Musí být číslo!</span>
                                        <span v-if="!$v.formResponses.price.minValue" class="help-block">Číslo musí být kladné!</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="row">
                                <div class="col-xs-6">
                                    <button type="button" class="btn btn-success" @click="submitForm()" :disabled="$v.formResponses.$invalid">Uložit změny</button> <br>
                                </div>
                                <div class="col-xs-6">
                                    <button type="button" class="btn btn-default btn-sm pull-right" @click="editMode = false">Zrušit úpravy</button>
                                </div>
                            </div>
                        </li>
                        <li style="display: none">
                            <div class="row">
                                <div class="col-xs-6">
                                    <button type="button" class="btn btn-danger btn-sm" @click="operationDelete()">Odstranit záznam</button>
                                </div>
                            </div>
                        </li> 
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const name = 'OperationDetail';

// Vuelidate
import { required, maxLength, requiredIf, decimal, minValue } from 'vuelidate/lib/validators'

import router from '../router';

// Select2
import Select2 from '../components/pageux/Select2';

import { mapGetters, mapActions } from "vuex";

export default {
    name,
    components: { 
        Select2,
    },
    data()
    {
        return {
            page: null,
            dontClosePage: false,
            editMode: false,

            formResponses: {
                type: 0,
                description: '',
                dph: "21%",
                invoice_price: 0,
                amount: 0,
                price: 0
            }
        }
    },
    validations: {
        formResponses: {
            description: {
                required,
                maxLength: maxLength(64)
            },
            invoice_price: {
                required,
                decimal,
                minValue: minValue(1)
            },
            price: {
                required: requiredIf(function () {
                    return this.formResponses.type === "Práce" ? 0 : 1
                }),
                decimal,
                minValue: minValue(0)
            },
            amount: {
                required,
                decimal,
                minValue: minValue(0)
            }
        }
    },
    computed: mapGetters(["getAllPages", 'currentOperation', 'currentOperationId']),
    methods: {
        ...mapActions(["makeActiveTab", "addTab", "removeTab", 'closeTab', "updateListOperation", 'makeCurrentListOperation', 'fetchListOperation']),
        async submitForm()
        {            
            if (this.$v.formResponses.$invalid) 
            {
                return 0;
            }
            
            const values = {
                type: this.formResponses.type === "Práce" ? 0 : 1,
                description: this.formResponses.description,
                dph: this.formResponses.dph,
                invoice_price: parseFloat(this.formResponses.invoice_price),
                amount: parseFloat(this.formResponses.amount),
                price: this.formResponses.price,
                id: this.currentOperationId
            }
            
            const res = await this.updateListOperation(values);

            if (res.code == "LIST_OPERATION_UPDATED")
            {
                this.fetchListOperation(this.currentOperationId)
                this.$alerts.toast('Záznam byl úspěšně upraven.', 'success');
                this.editMode = false
            }
            else
            {
                this.$alerts.message('Došlo k chybě při úpravě záznamu.', "", 'error');
            }
        },
        enableEdit()
        {
            this.formResponses.description = this.currentOperation.description
            this.formResponses.type = this.currentOperation.type === 0 ? "Práce" : "Materiál"
            this.formResponses.invoice_price = parseFloat(this.currentOperation.invoice_price)
            this.formResponses.dph = this.currentOperation.dph
            this.formResponses.amount = parseFloat(this.currentOperation.amount)
            this.formResponses.price = this.currentOperation.price

            this.editMode = true;
        },
        operationDelete()
        {
            /*this.$alerts.confirmDelete('Opravdu chcete tento záznam smazat?').then((result) => {
                if (result.value)
                {

                }
            });*/
        }
    },
    async created()
    {
        if (this.currentOperationId)
        {
            this.fetchListOperation(this.currentOperationId).then(() => {
                this.formResponses = {...this.formResponses,
                    type: this.currentOperation.type === 0 ? "Práce" : "Materiál",
                    description: this.currentOperation.description,
                    dph: this.currentOperation.dph,
                    invoice_price: parseFloat(this.currentOperation.invoice_price),
                    amount: parseFloat(this.currentOperation.amount),
                    price: this.currentOperation.price
                }

                this.page = this.getAllPages.filter(page => page.name == name)[0]
                this.addTab({...this.page, lng_name: this.currentOperation.description});
                this.makeActiveTab(this.page);
            });
        }
        else
        {
            this.closeTab(19)
            router.push({ name: 'Operations' })
        }
    }
}
</script>
<style scoped>
    @import "../assets/scss/app.scss";
</style>
